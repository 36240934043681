.axisLabel {
  font-size: 1.3em;
  fill: #fff;
  color: #fff;
}

.marks {
  fill: none;
  stroke: #ec820f;
  stroke-width: 1px;
}

.tick text {
  fill: #fff;
  cursor: default;
  font-size: 1em;
}

.tick line {
  fill: none;
  stroke: #534053;
}

.markerLine {
  /* stroke: #bd2d28; */
  stroke: #fa355f;
  stroke-width: 1px;
}

.baseLine {
  /* stroke: #bd2d28; */
  stroke: #fa355f;
  stroke-width: 1px;
  stroke-dasharray: 5, 5;
}

.voronoi path {
  fill: none;
  /* stroke: pink; */
  pointer-events: all;
}

.captureArea rect {
  fill: none;
  pointer-events: all;
}

.dataPoint {
  fill: #fa355f;
  stroke: #3f2d45;
  stroke-width: 2px;
}

.tooltip,
.tooltipStroke {
  fill: #fa355f;
  font-size: 1em;
  font-weight: bold;
}

.tooltipStroke {
  stroke: #3f2d45;
  stroke-width: 3px;
}
