@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300&display=swap");

html,
body {
  height: 100%;
  color: #e7e7e7;
  background: #160821;
  font-family: "Noto Sans TC", sans-serif;
}

.App {
  min-height: 100vh;
  background: rgb(62, 44, 68);
  background: radial-gradient(
    circle,
    rgba(62, 44, 68, 1) 0%,
    rgba(43, 25, 49, 1) 35%,
    rgba(22, 6, 31, 1) 100%
  );
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.nav {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  z-index: 3;
  height: calc(36px + 1vmin);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #160821;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  border-bottom: 4px solid #ea0b51;
}

.view-button {
  border: none;
  background-color: transparent;
  color: white;
  min-height: 44px;
  padding: 1em;
  text-decoration: none;
  font-size: calc(10px + 1vmin);
  font-weight: 700;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  font-family: "Noto Sans TC", sans-serif;
}

.view-button:hover {
  background-color: #990639 !important;
}

.active {
  background-color: #ea0b51 !important;
}

/*Charts*/
.card {
  background: rgba(80, 62, 85, 0.5);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding: 0.8vw;
  margin: 0.5vw;
}

.card:hover {
  /* background: rgba(80, 62, 85, 0.7); */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.8);
}

.flex-split {
  display: flex;
  flex-direction: column;
}

.flex-split > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.flex-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

ul.flex-card-list {
  padding: 0px;
  margin: 0px;
}

.flex-card-listitem {
  display: flex;
  position: relative;
}

.flex-card-list li {
  width: 50%;
}

.flex-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-evenly;
}

.chart-title {
  font-size: calc(12px + 1vmin);
  line-height: 1.1;
  margin: 0px 1em 2px 0px;
  font-family: "Noto Sans TC", sans-serif;
}

#charts-area {
  padding: 54px 1vw 0 1vw;
}

#global-indices > * {
  height: 23vh;
  min-height: 150px;
}

.quarter-page-chart {
  min-height: 38vh;
}

.sm-txt-on-bg {
  font-size: 0.8em;
  padding: 2px 4px 2px 6px;
  border-radius: 3px;
  float: right;
  color: white;
}
.bullish-bg {
  background-color: #e60f26;
}

.bearish-bg {
  background-color: #21a530;
}

.table-and-wave {
  display: flex;
  /*justify-content: space-between;*/
}

.table-chart {
  width: 65%;
  padding: 0.8vw;
}

.wave-comparison-bar {
  width: 35%;
  display: flex;
  flex-direction: column;
}

/*Data Table*/
.headerRow {
  background: #160821;
  color: white;
  font-size: 0.9em;
}
.headerCell {
  font-weight: 700;
}
.tableRow {
  background: rgba(80, 62, 85, 1);
  font-size: 1em;
  color: white;
}
.tableCell {
  text-align: center;
  font-family: monospace;
}
.selectedTableRow {
  background: #160821;
  color: white;
}
.hoverTableRow {
  background: #160821;
  color: white;
}
.google-visualization-table-table th.headerCell {
  border: solid #534053 1px;
}
.google-visualization-table-table td.tableCell {
  border: solid #534053 1px;
}

#heatmap {
  height: 91vh;
  padding: 0.8vw;
  position: relative;
  /* Centering the loading icon with flexbox */
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

.rising {
  color: #e60f26;
}

.falling {
  color: #21a530;
}

.no-change {
  color: orange;
}

.inline {
  display: inline;
}

.solo {
  z-index: 2;
  height: 91vh !important;
  width: 100vw !important;
}

/*Info messages*/
.isa_info,
.isa_success,
.isa_warning,
.isa_error {
  padding: 12px;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  border: 1.7px solid;
  border-radius: 5px;
}
.isa_info:before {
  content: "ℹ ";
}
.isa_success:before {
  content: "✔ ";
}
.isa_warning:before {
  content: "⚠ ";
}
.isa_error:before {
  content: "☓ ";
}
.isa_info {
  color: #00529b;
  background-color: #bde5f8;
}
.isa_success {
  color: #4f8a10;
  background-color: #dff2bf;
}
.isa_warning {
  color: #9f6000;
  background-color: #feefb3;
}
.isa_error {
  color: #d8000c;
  background-color: #ffd2d2ca;
  cursor: pointer;
}
.isa_error:active {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -42%);
}

/*Responsive layouts*/
@media all and (min-width: 64em) {
  /* On screens wider than a horizontal iPad */
  .flex-split {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .flex-split > div {
    width: 50%;
  }
}

@media all and (max-height: 27em) {
  /* On screens shorter than a horizontal mobile phone */
  .nav {
    height: 44px;
  }
  .quarter-page-chart {
    height: 72vh;
  }
  .table-and-wave {
    height: 72vh;
  }
  #stw-fitx-chart {
    height: 72vh;
  }
  #heatmap {
    height: 72vh !important;
  }
}

/* Collapsible */
.wrap-collapsible {
  margin: 1rem 0 0 0;
}

input[type="checkbox"] {
  display: none;
}

.lbl-toggle {
  display: block;

  font-weight: bold;
  font-family: monospace;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;

  padding: 0.5rem;

  color: #ebc6d3;
  background: #e60351;

  cursor: pointer;

  border-radius: 7px;
  transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
  color: white;
}

.lbl-toggle::before {
  content: " ";
  display: inline-block;

  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid currentColor;

  vertical-align: middle;
  margin-right: 0.7rem;
  transform: translateY(-2px);

  transition: transform 0.2s ease-out;
}

.collapsible-content .content-inner {
  background: #42222d;
  border-bottom: 1px solid #723b4e;

  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 0.5rem 1rem;
}

.collapsible-content {
  max-height: 0px;
  overflow: hidden;

  transition: max-height 0.25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 100vh;
}

.toggle:checked + .lbl-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}

.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* Loading Spinner */
.lds-spinner {
  color: official;
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fdd;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
